.contribute-button {
  margin-left: 18px;
}

.draw-block {
  text-align: center;
  margin-bottom: 20px;
}

.draw-block button {
  width: 360px; 
  margin: 0 auto;
}

.draw-form {
  width: "100%";
}

.draw-section {
  font-size: 40px;
  padding-bottom: 40px;
  padding-top: 40px;
}

.result-section {
  text-align: center;
  margin: 0 auto;
}

.draw-btn {
  width: 200px;
}

.legend {
  bottom: 20px !important;
  opacity: 0.65 !important;
}